import produce from 'immer';
import { setUpDragInHelper } from '../../components/boards/dashboard/board/boardHelpers';
import { DEFAULT_ITEMS } from '../../components/boards/dashboard/board/constants';
import httpHandler from '../../helpers/httpHandler';
import {
  Board,
  BoardItem,
  BoardListInstance,
  GroupCoachingBoard
} from '../../types/board';
import { Purchasables } from '../../types/course';
import { GetFn, SetFn, StoreProperties } from '../../types/zustandTypes';

const handleBoard = async (board: Board, clientId: number, set: SetFn) => {
  await getDashboardItems(board.id, clientId, set);
  set({
    currentBoard: board,
    selectedBg: board.selected_background,
    selectCopyBackground: board.selected_background.slice(4, -1)
  });
};

const getDashboardItems = async (
  boardId: number,
  clientId: number,
  set: SetFn
) => {
  try {
    const userDashboardItems: BoardItem[] = await httpHandler.get(
      `/api/board/${clientId}/${boardId}/items`
    );

    if (userDashboardItems.length === 0) {
      // Create default list here if this is the first time this dashboard is being visited.
      const newItemsGrid = await createBoardItems(boardId, clientId);

      set(
        produce((draft) => {
          draft.boardData.unfiltered.items = newItemsGrid;
        })
      );
    } else {
      let sortedWidgetList: BoardItem[] = [];
      //sort by date and add index with items no index
      if (
        userDashboardItems.length &&
        userDashboardItems[0].item_index === null
      ) {
        sortedWidgetList = [...userDashboardItems]
          .sort((a, b) => {
            return a.created_at > b.created_at ? -1 : 1;
          })
          .map((widget, index) => ({ ...widget, item_index: index }));

        updateGridItems(sortedWidgetList, boardId);
      } else {
        sortedWidgetList = [...userDashboardItems].sort(
          (a, b) => a.item_index - b.item_index
        );
      }

      set(
        produce((draft) => {
          draft.boardData.unfiltered.items = sortedWidgetList;
        })
      );
      setUpDragInHelper();
    }
  } catch (err) {
    console.error('err: ', err);
  }
};

const createBoardItems = async (boardId: number, clientId: number) => {
  const newItemsGrid: BoardItem[] = [];
  for (const currItem of DEFAULT_ITEMS) {
    try {
      const { addedGridItem } = await httpHandler.post(
        `/api/boards/${boardId}/items`,
        {
          x: currItem.x,
          y: currItem.y,
          w: currItem.w,
          h: currItem.h,
          type: currItem.type,
          userId: clientId,
          note: {
            userId: clientId
          },
          itemIndex: 0
        }
      );
      newItemsGrid.push(addedGridItem);
    } catch (err) {
      console.error("Couldn't POST new grid item: ", err);
    }
  }
  return newItemsGrid;
};

const getCoachBoard = async (clientId: number, set: SetFn) => {
  const board = await httpHandler.get(`/api/board/${clientId}/coaching`);

  set({
    membersAccess: board.allAccess,
    teamAccess: board.teamAccess || [],
    teamMembersAccess: board.teamMembersAccess || []
  });
  await handleBoard(board, clientId, set);
  return board;
};

const getTeamBoard = async (clientId: number, teamId: number, set: SetFn) => {
  const {
    dashboard: board,
    teamLeader,
    access,
    allAccess
  } = await httpHandler.get(`/api/teams/${teamId}/members/${clientId}/boards`);

  set({
    membersAccess: allAccess,
    teamLeader
  });
  await handleBoard(board, clientId, set);
  return { teamLeader, boardClientId: board.client_id, access, board };
};

const getPublicOrPrivateBoard = async (boardId: number, set: SetFn) => {
  const { board, allAccess, userAccess, teamMembersAccess, teamAccess } =
    await httpHandler.get(`/api/boards/${boardId}?access=true`);
  const tags = await httpHandler.get(`/api/boards/${boardId}/category-tags`);

  if (board.board_type === 'private') {
    set({ boardType: 'private' });
  }

  set({
    isTemplate: board.is_template ? true : false,
    membersAccess: allAccess,
    categories: tags,
    teamAccess: teamAccess || [],
    teamMembersAccess: teamMembersAccess || []
  });

  await handleBoard(board, board.client_id, set);

  return {
    board,
    allAccess,
    userAccess,
    teamMembersAccess,
    teamAccess,
    isPublicBoard: board.board_type === 'public'
  };
};

const getCoachData = async (clientId: number) => {
  try {
    const [clientCoach] = await httpHandler.get(
      `/api/infusionsoft/getUserIdsCoach/${clientId}`
    );
    return clientCoach;
  } catch (error) {
    console.error(`error getting Coach data`, error);
    return {};
  }
};

const getRecordings = async (userId: number) => {
  try {
    const data = await httpHandler.get(
      `/api/board/recordings/getRecordingsByParams/${userId}`
    );
    return data;
  } catch (error) {
    console.error(`error getting recordings`, error);
    return [];
  }
};

const getPurchasables = async (): Promise<Purchasables> => {
  try {
    const purchasableCoursesSectionsModules = await httpHandler.get(
      '/api/course/purchasableCoursesSectionsModules'
    );
    return purchasableCoursesSectionsModules;
  } catch (error) {
    console.error('getPurchasableCoursesSectionsModules error:', error);
    return { courses: [], sections: [], modules: [] };
  }
};

const getActivePurchasables = async (
  purchasables: Purchasables,
  set: SetFn,
  get: GetFn
) => {
  const overviews = {
    courses: get().boardData.unfiltered.courses,
    sections: get().boardData.unfiltered.sections,
    modules: get().boardData.unfiltered.modules
  };
  const purchasablesOverview = purchasables;
  const purchasableCourses = purchasablesOverview.courses.filter(
    (purchasableCourse) => {
      return !overviews.courses.some(
        (course) => course.id === purchasableCourse.id
      );
    }
  );
  const purchasableSections = purchasablesOverview.sections.filter(
    (purchasableSection) => {
      return !overviews.courses.some(
        (course) => course.id === purchasableSection.course_id
      );
    }
  );
  const purchasableModules = purchasablesOverview.modules.filter(
    (purchasableModule) => {
      return !overviews.courses.some(
        (course) => course.id === purchasableModule.course_id
      );
    }
  );

  set(
    produce((draft: StoreProperties) => {
      draft.boardData.unfiltered.purchasables = {
        courses: purchasableCourses,
        sections: purchasableSections,
        modules: purchasableModules
      };
    })
  );
};

const getCourseOverviews = async (userId: number, set: SetFn) => {
  const data = await httpHandler.get(
    '/api/course/permissions/viewWithFullTree',
    {
      clientId: userId
    }
  );

  const allSections: any[] = [];
  const allModules: any[] = [];

  data.forEach((course: any) => {
    Object.entries(course.sections).forEach(([id, section]: any) => {
      allSections.push({
        id,
        ...section,
        creatorName:
          course.creator_first_name &&
          course.creator_last_name &&
          `${course.creator_first_name} ${course.creator_last_name}`,
        courseImage: course.course_image && course.course_image
      });
    });
  });
  allSections.forEach((section, index) => {
    Object.entries(section.modules).forEach(([id, moduleName], index) => {
      allModules.push({
        id,
        moduleName,
        creatorName: section.creatorName && section.creatorName,
        courseImage: section.courseImage && section.courseImage
      });
    });
  });

  set(
    produce((draft: StoreProperties) => {
      draft.boardData.unfiltered.courses = data;
      draft.boardData.unfiltered.sections = allSections;
      draft.boardData.unfiltered.modules = allModules;
    })
  );
};

const deleteItem = async (
  widgetId: number,
  widgetType: string,
  boardId: number
) => {
  try {
    await httpHandler.delete(`/api/boards/${boardId}/items/${widgetId}`);
  } catch (error) {
    console.error('error: ', error);
  }

  switch (widgetType) {
    case 'note':
      try {
        await httpHandler.delete('/api/board/notes/deleteNote', {
          note: {
            widgetId: widgetId
          }
        });
      } catch (error) {
        console.error('error: ', error);
      }
      break;
    case 'homework':
      try {
        await httpHandler.delete(
          `/api/board/homeworks/deleteHomeworkByWidgetId`,
          { widgetId: widgetId }
        );
      } catch (error) {
        console.error('error: ', error);
      }
      break;
    case 'recording':
      try {
        await httpHandler.delete(
          `/api/board/recordings/deleteRecordingByWidgetId`,
          { widgetId: widgetId }
        );
      } catch (error) {
        console.error('error: ', error);
      }
      break;
    case 'list':
      try {
        await httpHandler.delete(
          `/api/board/widgetLists/deleteListAndItems/${widgetId}`
        );
      } catch (error) {
        console.error('error:', error);
      }
      break;
    default:
      break;
  }

  //update index in clident or server

  // update state here or in grid stack

  // set(
  //   produce((draft: StoreProperties) => {
  //     draft.boardData.unfiltered.items =
  //       draft.boardData.unfiltered.items.filter(
  //         (currItem) => currItem.id !== item.id
  //       );
  //   })
  // );
  // if (refs && gridRef) {
  //   delete refs[item.id];
  //   // gridRef.removeWidget(refs[item.id].current);
  // }
};

const addGridItem = async (
  item: any,
  board: Board,
  clickedToolbarCardRef: any,
  clientId: number,
  userId: number,
  set: SetFn,
  referenceId?: number
) => {
  const productivityList = [
    'note',
    'chart',
    'teamMembers',
    'homework',
    'list',
    'appointment',
    'progressOverTime',
    'clientCoachSelection'
  ];
  const type = item.el.classList[2];
  let isProductivity = productivityList.includes(type);
  const needsReferenceId = type === 'course';
  try {
    const newGridItem = await httpHandler.post(
      `/api/boards/${board.id}/items`,
      {
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
        reference_id: needsReferenceId
          ? clickedToolbarCardRef.current.course_id
          : null,
        type,
        clientId,
        userId,
        // referenceId,
        // note: {
        //   userId: userId
        // },
        // homework: {
        //   userId: userId
        // },
        recording: {
          videoSource: !isProductivity
            ? clickedToolbarCardRef.current.s3_location
            : null,
          image: !isProductivity ? clickedToolbarCardRef.current.image : null,
          description: !isProductivity
            ? clickedToolbarCardRef.current.detailed_description
            : null,
          title: !isProductivity ? clickedToolbarCardRef.current.topic : null,
          recording_date: !isProductivity
            ? clickedToolbarCardRef.current.start_time
            : null,
          occurrence_id: !isProductivity
            ? clickedToolbarCardRef.current.occurrence_id
            : null
        },
        section: {
          sectionId: !isProductivity ? clickedToolbarCardRef.current.id : null,
          sectionName: !isProductivity
            ? clickedToolbarCardRef.current.sectionName
            : null,
          modules: !isProductivity
            ? clickedToolbarCardRef.current.modules
            : null
        },
        currentModule: {
          moduleId: !isProductivity ? clickedToolbarCardRef.current.id : null,
          moduleName: !isProductivity
            ? clickedToolbarCardRef.current.moduleName
            : null
        },
        isPurchasable: clickedToolbarCardRef?.current?.isPurchasable || 0
      }
    );
    return { ...newGridItem, type };
  } catch (err) {
    console.error("Couldn't POST new grid item: ", err);
  }
};

const addMobileItem = async (
  type: string,
  data: any,
  clientId: number,
  userId: number,
  board: Board,
  set: SetFn
) => {
  let newItem;
  const needsReferenceId = type === 'course';
  try {
    newItem = await httpHandler.post(`/api/boards/${board.id}/items`, {
      w: 3,
      h: 3,
      reference_id: needsReferenceId ? data.id : null,
      type,
      userId,
      // referenceId,
      note: {
        userId: userId
      },
      recording: {
        videoSource: data?.s3_location,
        image: data?.image,
        description: data?.detailed_description,
        title: data?.topic,
        recording_date: data?.start_time
      },
      section: {
        sectionId: data?.id,
        sectionName: data?.sectionName,
        modules: data?.modules
      },
      currentModule: {
        moduleId: data?.id,
        moduleName: data?.moduleName
      }
    });
    return { ...newItem, type };
  } catch (err) {
    console.error("Couldn't POST new grid item: ", err);
  }
};

const getUserBoards = async () => {
  try {
    const data = await httpHandler.get('/api/board/me');
    const {
      boards,
      memberBoards
    }: { boards: BoardListInstance[]; memberBoards: BoardListInstance[] } =
      data;
    const filteredMemberBoards = memberBoards.filter(
      (board) =>
        board.role && board.role === 'write' && board.board_type !== 'team'
    );
    const allBoards = [...filteredMemberBoards, ...boards];

    const userBoards = await getCoachingBoard(allBoards);
    return userBoards;
  } catch (error) {
    console.error('error in getBoards:', error);
    return [];
  }
};

const getCoachingBoard = async (allBoards: BoardListInstance[]) => {
  try {
    const coachingBoard: BoardListInstance = await httpHandler.get(
      '/api/board/me/coaching'
    );

    if (coachingBoard) {
      return [coachingBoard, ...allBoards];
    } else {
      return allBoards;
    }
  } catch (error) {
    console.error(`error in getCoachingBoard()`, error);
    return allBoards;
  }
};

const getGroupCoachingBoards = async () => {
  try {
    const boards: GroupCoachingBoard[] = await httpHandler.get(
      '/api/board/me/group-coaching'
    );
    return boards || [];
  } catch (error) {
    console.error('error get group coaching board', error);
  }
};

const updateGridItems = async (itemsToUpdate: BoardItem[], boardId: number) => {
  try {
    await httpHandler.patch(`/api/boards/${boardId}/items`, {
      items: itemsToUpdate
    });
  } catch (err) {
    console.error('Error updating gridItem: ', err);
  }
};

const boardApi = {
  getCoachData,
  getCoachBoard,
  getTeamBoard,
  getPublicOrPrivateBoard,
  getRecordings,
  getPurchasables,
  getActivePurchasables,
  getCourseOverviews,
  deleteItem,
  addGridItem,
  addMobileItem,
  getUserBoards,
  updateGridItems,
  getGroupCoachingBoards
};
export default boardApi;
