import produce from 'immer';
import { Recording } from '../../types/recording';
import { User } from '../../types/user';
import {
  GetFn,
  SetFn,
  BoardSlice,
  StoreProperties
} from '../../types/zustandTypes';
import boardApi from '../apis/boardApi';

export const createBoardSlice = (set: SetFn, get: GetFn): BoardSlice => ({
  // Initial State Properties
  currentBoard: null,
  coach: null,
  boardType: '',
  teamLeader: null,
  accessLevel: 'read',
  isAdmin: false,
  membersAccess: [],
  teamAccess: [],
  teamMembersAccess: [],
  allMemberIds: [],
  paramItemId: null,
  boardUrl: '',
  isTemplate: false,
  categories: [],
  creatorId: null,
  selectedBackground:
    'https://images.pexels.com/photos/3417015/pexels-photo-3417015.jpeg',
  selectCopyBackground: '',
  createBoardModal: false,
  isCall: false,
  hasPermission: true,
  activeSettingsTab: 'menu',
  itemLimit: 12,
  selectedBg: '',
  searchInput: '',
  dateRange: null,
  userBoards: [],
  groupCoachingBoards: [],
  accessableBoardIds: [],
  boardData: {
    unfiltered: {
      items: [],
      mobileItems: [],
      recordings: [],
      courses: [],
      sections: [],
      modules: [],
      productivityWidgets: [
        'Charts',
        'Team Members',
        'Notes',
        'Homework',
        'Appointments',
        'List',
        'Progress Over Time'
      ],
      purchasables: {
        courses: [],
        sections: [],
        modules: []
      }
    },
    filtered: {
      filteredProductivityWidgets: [],
      filteredRecordings: [],
      filteredRecordingsByDate: [],
      filteredCourses: [],
      filteredSections: [],
      filteredModules: [],
      filteredPurchasableCourses: [],
      filteredPurchasableSections: [],
      filteredPurchasableModules: []
    }
  },

  // API
  boardApi: {
    set: {
      coach: (coach: User) => {
        set({ coach });
      },
      currentBoard: (currentBoard) => set({ currentBoard }),
      boardType: (boardType) => set({ boardType }),
      teamLeader: (teamLeader) => set({ teamLeader }),
      accessLevel: (accessLevel) => set({ accessLevel }),
      isAdmin: (isAdmin) => set({ isAdmin }),
      memberAccess: (membersAccess) => set({ membersAccess }),
      teamAccess: (teamAccess) => set({ teamAccess }),
      teamMembersAccess: (teamMembersAccess) => set({ teamMembersAccess }),
      allMemberIds: (allMemberIds) => set({ allMemberIds }),
      paramItemId: (paramItemId) => set({ paramItemId }),
      boardUrl: (boardUrl) => set({ boardUrl }),
      isTemplate: (isTemplate) => set({ isTemplate }),
      categories: (categories) => set({ categories }),
      creatorId: (creatorId) => set({ creatorId }),
      selectedBackground: (selectedBackground) => set({ selectedBackground }),
      selectCopyBackground: (selectCopyBackground) =>
        set({ selectCopyBackground }),
      createBoardModal: (isVisible) => set({ createBoardModal: isVisible }),
      isCall: (isCall) => set({ isCall }),
      hasPermission: (hasPermission) => set({ hasPermission }),
      dateRange: (dateRange) => set({ dateRange }),
      userBoards: (userBoards) => set({ userBoards }),
      groupCoachingBoards: (boards) => set({ groupCoachingBoards: boards }),
      accessableBoardIds: (ids: number[]) => set({ accessableBoardIds: ids }),
      activeSettingsTab: (activeSettingsTab) => set({ activeSettingsTab }),
      itemLimit: (itemLimit) => set({ itemLimit }),
      selectedBg: (selectedBg) => set({ selectedBg }),
      searchInput: (searchInput) => set({ searchInput }),
      recordings: (recordings) =>
        set(
          produce((draft) => {
            draft.boardData.unfiltered.recordings = recordings;
          })
        ),
      items: (items) =>
        set(
          produce((draft) => {
            draft.boardData.unfiltered.items = [...items];
          })
        ),
      mobileItems: (mobileItems) =>
        set(
          produce((draft) => {
            draft.boardData.unfiltered.mobileItems = mobileItems;
          })
        ),
      courses: (courses) =>
        set(
          produce((draft) => {
            draft.boardData.unfiltered.courses = courses;
          })
        ),
      sections: (sections) =>
        set(
          produce((draft) => {
            draft.boardData.unfiltered.sections = sections;
          })
        ),
      modules: (modules) =>
        set(
          produce((draft) => {
            draft.boardData.unfiltered.modules = modules;
          })
        ),
      purchasables: (purchasables) =>
        set(
          produce((draft) => {
            draft.boardData.unfiltered.purchasables = purchasables;
          })
        ),
      productivityWidgets: (productivityWidgets) =>
        set(
          produce((draft) => {
            draft.boardData.filtered.productivityWidgets = productivityWidgets;
          })
        ),
      filteredProductivityWidgets: (filteredProductivityWidgets) =>
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredProductivityWidgets =
              filteredProductivityWidgets;
          })
        ),
      filteredRecordings: (filteredRecordings) => {
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredRecordings = filteredRecordings;
          })
        );
      },
      filteredRecordingsByDate: (filteredRecordingsByDate) =>
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredRecordingsByDate =
              filteredRecordingsByDate;
          })
        ),
      filteredCourses: (filteredCourses) => {
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredCourses = filteredCourses;
          })
        );
      },
      filteredSections: (filteredSections) => {
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredSections = filteredSections;
          })
        );
      },
      filteredModules: (filteredModules) => {
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredModules = filteredModules;
          })
        );
      },
      filteredPurchasableCourses: (filteredPurchasableCourses) =>
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredPurchasableCourses =
              filteredPurchasableCourses;
          })
        ),
      filteredPurchasableSections: (filteredPurchasableSections) => {
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredPurchasableSections =
              filteredPurchasableSections;
          })
        );
      },
      filteredPurchasableModules: (filteredPurchasableModules) => {
        set(
          produce((draft) => {
            draft.boardData.filtered.filteredPurchasableModules =
              filteredPurchasableModules;
          })
        );
      }
    },
    getCoachData: async (clientId) => {
      const coach: User = await boardApi.getCoachData(clientId);
      set({ coach });
    },
    getBoard: async ({ clientId, user, boardType, boardId, teamId }) => {
      try {
        let hasAccess = true;

        switch (boardType) {
          case 'coaching':
            const board = await boardApi.getCoachBoard(clientId, set);

            const coach: User | undefined = await boardApi.getCoachData(
              clientId
            );
            if (coach) set({ coach });

            if (
              user.id !== coach?.id &&
              user.id !== +clientId &&
              user.access_level !== 'admin' &&
              !board.access
            ) {
              hasAccess = false;
            }

            if (
              board?.access?.role === 'read' &&
              user.id !== coach?.id &&
              user.id !== clientId &&
              user.access_level !== 'admin'
            ) {
              set({ accessLevel: 'read' });
            } else {
              set({ accessLevel: 'write' });
            }

            return { hasAccess, board };

          case 'team':
            if (teamId) {
              const { teamLeader, boardClientId, access, board } =
                await boardApi.getTeamBoard(clientId, teamId, set);
              if (
                +user.id !== +teamLeader.id &&
                +user.id !== +boardClientId &&
                user.access_level !== 'admin' &&
                !access
              ) {
                hasAccess = false;
              }
              return { hasAccess, board };
            }
            return { hasAccess: false, board: null };

          case 'public':
            if (boardId) {
              const { userAccess, isPublicBoard, board } =
                await boardApi.getPublicOrPrivateBoard(boardId, set);
              if (userAccess.isAdmin || userAccess.isCreator) {
                set({ isAdmin: true, accessLevel: 'write' });
              } else if (userAccess.access) {
                set({ accessLevel: userAccess.access.role });
              } else {
                set({ accessLevel: isPublicBoard ? 'read' : 'none' });
                if (!isPublicBoard) hasAccess = false;
              }
              return { hasAccess, board };
            }
            return { hasAccess: false, board: null };

          default:
            return { hasAccess: false, board: null };
        }
      } catch (error) {
        console.error(`error getting board`, error);
        return { hasAccess: false, board: null };
      }
    },
    getGroupCoachingBoards: async () => {
      const groupCoachingBoards = await boardApi.getGroupCoachingBoards();
      set({ groupCoachingBoards: groupCoachingBoards || [] });
    },

    getRecordings: async (userId) => {
      const recordings: Recording[] = await boardApi.getRecordings(userId);
      set(
        produce((draft: StoreProperties) => {
          draft.boardData.unfiltered.recordings = recordings;
        })
      );
    },
    getUserBoards: async () => {
      const boards = await boardApi.getUserBoards();
      const groupCoachingBoards =
        (await boardApi.getGroupCoachingBoards()) || [];
      set((state) => ({
        userBoards: [
          ...new Map(
            [...state.userBoards, ...boards, ...groupCoachingBoards].map(
              (board) => [board.id, board]
            )
          ).values()
        ]
      }));
    },
    getCourses: async (userId) => {
      await boardApi.getCourseOverviews(userId, set);
      const purchasables = await boardApi.getPurchasables();
      await boardApi.getActivePurchasables(purchasables, set, get);
    },
    deleteItem: async (widgetId, widgetType, boardId) => {
      await boardApi.deleteItem(widgetId, widgetType, boardId);
    },
    addGridItem: async (
      item,
      board,
      clickedToolbarCardRef,
      clientId,
      userId,
      referenceId
    ) => {
      const results = await boardApi.addGridItem(
        item,
        board,
        clickedToolbarCardRef,
        clientId,
        userId,
        set,
        referenceId
      );
      return results;
    },
    updateGridItems: async (items, boardId) => {
      const results = await boardApi.updateGridItems(items, boardId);
      return results;
    },
    addMobileItem: async (type, data, clientId, userId, board) => {
      const results = await boardApi.addMobileItem(
        type,
        data,
        clientId,
        userId,
        board,
        set
      );
      return results;
    },
    cleanUpBoard: () => {
      set({
        currentBoard: null,
        coach: null,
        boardType: '',
        teamLeader: null,
        accessLevel: 'write',
        isAdmin: false,
        membersAccess: [],
        teamAccess: [],
        teamMembersAccess: [],
        allMemberIds: [],
        paramItemId: null,
        boardUrl: '',
        isTemplate: false,
        categories: [],
        creatorId: null,
        selectedBackground:
          'https://images.pexels.com/photos/3417015/pexels-photo-3417015.jpeg',
        selectCopyBackground: '',
        createBoardModal: false,
        isCall: false,
        hasPermission: true,
        activeSettingsTab: 'menu',
        itemLimit: 12,
        selectedBg: '',
        searchInput: '',
        dateRange: null,
        userBoards: [],
        boardData: {
          unfiltered: {
            items: [],
            mobileItems: [],
            recordings: [],
            courses: [],
            sections: [],
            modules: [],
            productivityWidgets: [
              'Charts',
              'Team Members',
              'Notes',
              'Homework',
              'Appointments',
              'List',
              'Progress Over Time'
            ],
            purchasables: {
              courses: [],
              sections: [],
              modules: []
            }
          },
          filtered: {
            filteredProductivityWidgets: [],
            filteredRecordings: [],
            filteredRecordingsByDate: [],
            filteredCourses: [],
            filteredSections: [],
            filteredModules: [],
            filteredPurchasableCourses: [],
            filteredPurchasableSections: [],
            filteredPurchasableModules: []
          }
        }
      });
    }
  }
});
